import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/6b14766d-2553-4ad5-8e6a-1af8b88b0485/styles/typography.css");
